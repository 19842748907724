.clock-style span:first-child{
    display:inline-block;
    font-size: 36px;
}

.clock-style span:nth-child(2){
    display:inline-block;
    margin-left: 5px;
}

.clock-style span:nth-child(2) div{
   font-size: 12px;
}

.clock-style span:nth-child(4){
    font-size: 12px; 
}


.show-cell {
    background: rgb(153, 209, 255);
    border-left: 1px solid rgb(17, 4, 4) !important;
    border-right: 1px solid rgb(0, 0, 0) !important;
    border-bottom: 1px solid rgb(8, 1, 1) !important;
  }
  
  .show-name {
    font-weight: bold;
  }
  
  .show-presenter {
    font-style: italic;
  }


  .notify-container{
    position: relative;
  }
  .count-badge{
    /* width: 18px; */
    height: 18px;
    border-radius: 18px;
    background-color: #ff2b2b;
    position: absolute;
    top: -6px;
    right: -12px;
    /* width: 15px;
    height: 15px;
    border-radius: 18px;
    background-color: #ff2b2b;
    position: absolute;
    top: -3px;
    right: -7px; */
  }
.notify-container i{
  position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    color: #333333;
    background: #dddddd;
    border: none;
    outline: none;
    border-radius: 50%;
}

.notify-container div{
  position: absolute;
    top: -15px;
    right: -10px;
    width: 35px;
    height: 35px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
}
  .count-badge div{
    text-align:center;
    font-size: 13px;
    position: absolute;
    top: -15px;
    right: -10px;
    width: 35px;
    height: 35px;
    background: red;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
  }
  
  .fa-bell{
    color:aliceblue; 
  }


  .searchbox-container{
    background-color: white !important;    
  }
  .navbar-search-dark .form-control{
    color: rgb(171 171 171 / 90%);
  }

  .search-box::placeholder{
    color:rgb(177, 177, 177) !important;
  }

  .search-box{
    background-color: white !important;
    border-radius: 50px !important;
  }

  .fa-search{
    color:grey;
  }

  .bg-bd{
    margin-bottom: 35px;
    background-color: #f8f9fe !important;
  }
  .header-title{
    font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 36px;
line-height: 42px;

color: #444444;
  }