@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
body {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
}
.custom-heading {
  margin-bottom: 35px;
}
.custom-heading h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 32px;
  line-height: 38px;
  color: #7c7c7c;
}
.custom-heading #search {
  background: #ffffff;
  border: 1px solid #7a69f0;
  border-radius: 8px;
}
.card-title {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  color: #898989;
}
.css-13cymwt-control {
  background: #f2e8ff;
  border: 1px solid #7a69f0;
  border-radius: 12px;
}
.cardstyle label {
  max-width: none;
  text-align: center;
  width: 100%;
}
.cardstyle label span {
}
.cardstyle label svg {
  width: 100px;
  height: 100px;
}
.cardstyle .notice {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 15px;
  color: #ff8181;
  text-align: center;
}
.cardstyle .file-types {
  display: none;
}
.fileuploadwapper {
  /* display: flex; */
  align-items: center;
  justify-content: center;
  margin: 0;
  border: 6px dashed #7a69f026;
  border-radius: 16px;
  width: 100%;
  padding: 5px 0px;
}
.fileicons {
}
/* .fileuploadwapper .fileicons i{
font-size: 30px;
transform: matrix(0.96, 0.25, -0.27, 0.97, 0, 0);
} */

.fileuploadwapper .fileicons .first {
  font-size: 40px;
  transform: matrix(0.94, -0.32, 0.35, 0.94, 0, 0);
  color: #444444;
  margin: 0px 2px;
}
.first {
  margin-top: 10px !important;
}
.fileuploadwapper .fileicons .second {
  font-size: 40px;
  color: #7a69f0;
  margin: 0px 2px;
}

.fileuploadwapper .fileicons .third {
  font-size: 40px;
  transform: matrix(0.96, 0.25, -0.27, 0.97, 0, 0);
  color: #444444;
  margin: 0px 2px;
}

.cardstyle .fileuploadwapper .fileicons .para1 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 28px;
  color: #666666;
}

.fileicons .para1 span {
  color: #7a69f0;
}
.card-title Button {
  font-size: 20px;
}
.right-card .card-title {
  text-align: left;
}
.rightcard-below-card {
}

.rightcard-below-card .card {
  padding: 0px;
  margin: 22px 0;
}
.rightcard-below-card .card-body {
  background: #f3eaff;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}
.custom-below {
  margin: 50px 0;
}
.custom-below h2 {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 33px;
  /* identical to box height */
  color: #7c7c7c;
  padding-right: 25px;
}
.custom-below .filters {
  display: flex;
}
.recentupdate .date {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: #898989;
}
.recentupdate .filename {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 21px;
    color: #666666;
  }
.delete_btn{
  background: #FFD8D8;
  border-radius: 6px;
  border: none;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 21px;
  color: #F71010;
  height: 42px;
  }
  .Delete_div{
    width: 100%;
    background: rgb(227, 242, 253);
  }
  .Delete_div .button_left{
    margin-top: 12px;
  }
  .Delete_div .button_right{
    margin: 5px 0;
  }

  @media (min-width: 768px){
    .pt-md-8, .py-md-8 {
      padding-top: 3rem !important;
 }
  }
  .progress-wrapper{
    position: relative;
    padding-top: 1.5rem;
    background: #FFFFFF;
    border: 1px solid #F2E8FF;
    border-radius: 4px;
  }
  .fileicons2 i{
    font-size: 60px;
    color: #7A69F0;
  }
  .selected_file{
    font-weight: 700;
    margin: 0px;
  }

#seachid:focus{
  background-color: #7A69F0;
  color: #fff;
}
#seachid:focus ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: red;
  opacity: 1; /* Firefox */
}
#otherfileinput{
  font-size: 14px;
}